import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaginationTable } from './PaginationTable';
import { isEmpty } from 'lodash';
import { PageSizeTable } from './PageSizeTable';
import classNames from 'classnames';

const Table = ({
  columns,
  dataTable,
  usePagination = false,
  isShowPageSize = false,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationInfo, setPaginationInfo] = useState();

  const handlePageSizeChange = (pageSize) => {
    dataTable.pagination.page = 1;
    dataTable.pagination.page_size = pageSize.value;
    setPaginationInfo(dataTable.pagination);
    searchParams.set('page', '1');
    searchParams.set('page_size', String(pageSize.value));
    setSearchParams(searchParams);
  };

  const handlePageChange = (page) => {
    dataTable.pagination.page = page;
    setPaginationInfo(dataTable.pagination);
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!isEmpty(dataTable.pagination)) {
      setPaginationInfo(dataTable.pagination);
    }
  }, [dataTable.pagination]);

  return (
    <div>
      <table className="table table-hover">
        <thead>
          <tr>
            {columns(dataTable.data)[0].map((cell) => (
              <th scope="col" key={cell.id} className={cell.classNameHeader}>
                {cell.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {columns(dataTable.data).map((rows, indexRows) => (
            <tr key={indexRows}>
              {rows.map((cell, indexCell) => (
                <td scope="row" className={cell.classNameCell} key={indexCell}>
                  {cell.cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!isEmpty(paginationInfo) && usePagination && (
        <div className="my-4">
          <div
            className={classNames('d-flex align-items-center', {
              'justify-content-center': !isShowPageSize,
              'justify-content-between': isShowPageSize,
            })}>
            {isShowPageSize && (
              <PageSizeTable
                event={(pageSize) => handlePageSizeChange(pageSize)}
                pageSize={paginationInfo.page_size}
              />
            )}
            <PaginationTable
              onPageChange={(page) => handlePageChange(page)}
              pagination={paginationInfo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
