import React from 'react';

const Network = ({ data }) => {
  return (
    <section className="network">
      <div className="network-inner">
        <div className="container">
          <div className="section-title " data-aos="fade-down">
            <h3>Social network</h3>
            <span></span>
          </div>
          <p className="section-content " data-aos="fade-up">
            <strong>Cung cấp dịch vụ truyền thông</strong> trên hệ thống fanpage
            &amp; group Facebook, bao gồm nhiều lĩnh vực như thể thao điện tử,
            giải trí, comic, showbiz,…{' '}
          </p>
          <div className="network-list">
            <div className="row">
              {data.left.map((item, index) => (
                <div className="col-md-6 " data-aos="fade-left" key={index}>
                  <div className="network-item">
                    <div className="network-img">
                      <img src={item.url} alt="network" />
                    </div>
                    <div className="network-content">
                      <span>{item.name}</span>
                      <span>
                        {item.follower} triệu <small>followers</small>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {data.right.map((item, index) => (
                <div className="col-md-6 " data-aos="fade-right" key={index}>
                  <div className="network-item">
                    <div className="network-img">
                      <img src={item.url} alt="network" />
                    </div>
                    <div className="network-content">
                      <span>{item.name}</span>
                      <span>
                        {item.follower} triệu <small>followers</small>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Network;
