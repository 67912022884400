import RecruitmentDetailPage from '@/components/screens/recruiment/RecruitmentDetailPage';
import RecruitmentPage from '@/components/screens/recruiment/RecruitmentPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const RecruimentWrapper = () => {
  return (
    <Routes>
      <Route path="" element={<RecruitmentPage />} />
      <Route path=":id" element={<RecruitmentDetailPage />} />
    </Routes>
  );
};

export default RecruimentWrapper;
