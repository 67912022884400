import KOLsDetailPage from '@/components/screens/kols/KOLsDetailPage';
import KOLsPage from '@/components/screens/kols/KOLsPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const KOLsWrapper = () => {
  return (
    <Routes>
      <Route path="" element={<KOLsPage />} />
      <Route path=":id" element={<KOLsDetailPage />} />
    </Routes>
  );
};

export default KOLsWrapper;
