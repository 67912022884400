import { useMemo } from 'react';

const PaginateRange = ({ pagination, pageNumber = 3 }) => {
  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, index) => index + start);
  };

  return useMemo(() => {
    const totalPageCount = pagination.total_pages;
    const currentPage = pagination.page;

    // Pages count is determined as pageNumber + firstPage + lastPage + currentPage
    const totalPageNumbers = pageNumber + 1;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      PaginateWidget, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - pageNumber, 1);
    const rightSiblingIndex = Math.min(
      currentPage + pageNumber,
      totalPageCount,
    );

    const shouldShowLeftDots = leftSiblingIndex >= 2;
    const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 1 + 2 * pageNumber;
      let leftRange = range(1, leftItemCount);
      return [...leftRange];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 1 + 2 * pageNumber;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [...middleRange];
    }
  }, [pagination, pageNumber]);
};

export { PaginateRange };
