import React from 'react';
import { Modal } from 'react-bootstrap';

const TikTokModal = ({ show, dataModal, handleClose }) => {
  return (
    <Modal
      fullscreen="sm-down"
      show={show}
      centered
      onHide={handleClose}
      className="modal-tiktok"
      backdrop="static">
      <Modal.Header closeButton />
      <Modal.Body>
        <iframe
          src={dataModal}
          width="100%"
          height="100%"
          className="min-vh-75"></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default TikTokModal;
