import React from 'react';
import Slider from 'react-slick';

const settingsHeader = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
};

const Banner = ({ data }) => {
  return (
    <section className="banner">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div
              className="banner-info "
              data-aos="fade-up"
              data-aos-delay="100">
              <h1>
                CUNG CẤP DỊCH VỤ <br />
                <strong>
                  MARKETING, TRUYỀN THÔNG <br />
                  &amp; NỘI DUNG SỐ ĐA NỀN TẢNG
                </strong>
              </h1>
            </div>
          </div>
        </div>
        <div className="bg-1"></div>
        <div className="bg-2"></div>
        <div className="bg-3"></div>
        {data.map((slider, index) => (
          <div
            className={`business-four-box business-${index + 1} main-box-image`}
            key={index}>
            <img src={slider} alt={`banner-${index + 1}`} />
          </div>
        ))}
        <div className="banner-list slick-initialized slick-slider slick-dotted">
          <Slider {...settingsHeader}>
            {data.map((slider, index) => (
              <div
                className="banner-item"
                style="width: 100%; display: inline-block;"
                key={index}>
                <img src={slider} alt={`banner-mobile-${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Banner;
