import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';

const KOLsDetailPage = () => {
  return (
    <section className="detail-section">
      <div className="container">
        <div className="detail-title"> Talent Manager (HCM) </div>
        <div className="detail-time">
          <span>
            <img
              alt="Calendar"
              src={toAbsoluteUrl('/assets/box-studio/Calendar.svg')}
            />
            Hạn nộp hồ sơ: 2023-04-23 18:50:24{' '}
          </span>
          <span>
            <img
              src={toAbsoluteUrl('/assets/box-studio/Location.svg')}
              alt="Location"
            />
            Địa điểm làm việc: 19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM{' '}
          </span>
        </div>
        <div
          className="detail-content recruit-content"
          dangerouslySetInnerHTML={{
            __html: `
			<p class="font-weight-bold"></p>
			<p><strong><span style="text-decoration: underline;">Mô tả vị trí</span>:</strong></p>
<p style="text-align: justify; line-height: 1.4;"><span style="color: #333333; font-family: arial, helvetica, sans-serif; font-size: 12pt; background-color: #ffffff;">-&nbsp;<span id="docs-internal-guid-7a2f2b37-7fff-e4c7-98fc-c88e425ef778"><span style="color: #000000; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Chịu trách nghiệm tìm kiếm Creator/ đối tác tham gia network;</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span><span id="docs-internal-guid-bd6326ce-7fff-32c1-705d-a46881923f07"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Phối hợp cùng Head xây dựng tài liệu, cẩm nang phát triển Meta cho Creator;</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span><span id="docs-internal-guid-06692f67-7fff-5a7c-291c-0dc8cc2448a8"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Phối hợp cùng Content xây dựng trường nội dung (Food, Lifestyle, Entertainment, diễn xuất,...) và tạo event phát triển Group Community, phát triển fanpage Box Network nhằm mục đích branding MCN;</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span><span id="docs-internal-guid-c79aea24-7fff-85f6-8af3-cb450727e32d"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Xây dựng và phát triển chiến lược độc đáo cho Box nhằm phát triển mạng lưới đa kênh, tìm kiếm các phương thức tăng trưởng doanh thu trong lĩnh vực này;</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span><span id="docs-internal-guid-e85f5534-7fff-acb9-8826-16f9f9a874f4"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Phát triển và duy trì danh tiếng của Box và mối quan hệ khách hàng, những người có tầm ảnh hưởng ở nhiều nền tảng social;</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span></span><span style="font-family: arial, helvetica, sans-serif;"><span style="white-space-collapse: preserve;">Hỗ trợ làm job phòng kinh doanh và các công việc khác theo phân công của Leader.</span></span></p>
<p><strong><span style="text-decoration: underline;">Yêu cầu vị trí</span>:&nbsp;</strong></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span><span id="docs-internal-guid-1c455328-7fff-0103-efa1-d7d20ce17f1e"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Có ít nhất 01 năm kinh nghiệm trong lĩnh vực Social/Marketing/Influencer;</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span><span id="docs-internal-guid-af4c7ab1-7fff-c1d2-3894-e4816f61419b"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Có khả năng xây dựng Social - TikTok/FaceBook/Youtube là 1 lợi thế;</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span><span id="docs-internal-guid-38b36e1a-7fff-6770-f3f9-bd70cf81383e"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline; white-space: pre-wrap;">Có khả năng quản lý, sắp xếp, triển khai, thuyết trình, giao tiếp, đàm phán…</span></span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;"><span style="color: #333333; background-color: #ffffff;">-&nbsp;</span></span><span style="font-family: arial, helvetica, sans-serif;"><span style="white-space-collapse: preserve;">Ưu tiên IELTS từ 5.5 hoặc trình độ Tiếng anh tương đương;</span></span></p>
<p style="text-align: justify; line-height: 1.4;"><span style="color: #333333; font-family: arial, helvetica, sans-serif; font-size: 12pt; background-color: #ffffff;">- Có khả năng quản lý thời gian, sắp xếp công việc và multitask;</span></p>
<p style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; max-height: 400px; overflow: hidden; color: #282828; font-family: 'Source Sans Pro', sans-serif; font-size: 16px; text-align: justify; background-color: #ffffff;"><span style="box-sizing: border-box; margin: 0px auto; font-weight: bolder;"><span style="box-sizing: border-box; margin: 0px auto; text-decoration-line: underline;">Quyền lợi và đãi ngộ</span>:</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Mức lương cạnh tranh, chế độ 13 tháng lương;</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Được làm việc trong công ty tiên phong trong lĩnh vực truyền thông ở Việt Nam;</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Tham gia làm việc trong môi trường cạnh tranh, tiếp xúc rất nhiều với người nổi tiếng, các diễn viên, đơn vị sản xuất;</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Đánh giá tăng lương ít nhất 1 lần/năm;</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Được đóng đầy đủ các loại bảo hiểm theo quy định của nhà nước, hưởng các trợ cấp và phúc lợi theo quy định của công ty và luật lao động;</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Môi trường làm việc năng động, hòa đồng, có cơ hội hoàn thiện và phát triển bản thân cũng như nâng cấp vị trí;</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Đồng nghiệp, môi trường thoải mái và cực kỳ hỗ trợ nhau vì mục tiêu chung;</span></p>
<p style="box-sizing: border-box; padding: 0px; color: #333333; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 14px; background-color: #ffffff; width: unset !important; line-height: 2; margin: 0px 0px 10px 0px !important;"><span style="font-size: 12pt;">- Tham gia các hoạt động thường niên của team và công ty: Du lịch, Teambuilding, giao lưu định kỳ....</span></p>
<p style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; max-height: 400px; overflow: hidden; color: #282828; font-family: 'Source Sans Pro', sans-serif; font-size: 16px; text-align: justify; background-color: #ffffff;"><span style="box-sizing: border-box; margin: 0px auto; font-weight: bolder;"><span style="box-sizing: border-box; margin: 0px auto; text-decoration-line: underline;">Cách thức ứng tuyển</span>:</span></p>
<p style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; max-height: 400px; overflow: hidden; color: #282828; font-family: 'Source Sans Pro', sans-serif; font-size: 16px; text-align: justify; background-color: #ffffff;">- Gửi CV về hòm mail: Tuyendung@box.studio&nbsp;</p>`,
          }}></div>
      </div>
    </section>
  );
};

export default KOLsDetailPage;
