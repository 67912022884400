import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';

const Partner = ({ data }) => {
  return (
    <section className="partner">
      <div className="container">
        <div className="section-title " data-aos="fade-down">
          <h3>Đối tác của chúng tôi</h3>
          <span></span>
        </div>
        <div className="row " data-aos="fade-up">
          {data.map((item, index) => (
            <div className="col-md-2 col-4" key={index}>
              <div className="partner-item">
                <img
                  src={toAbsoluteUrl(
                    `/assets/box-studio/partner${
                      item.status === 'old' ? '/' : '/new'
                    }/${item.name}.png`,
                  )}
                  alt={item.name}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partner;
