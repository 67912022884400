import React from 'react';

const NotFound = () => {
  return (
    <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
      <h1
        style={{
          textAlign: 'center',
          fontSize: '56px',
          marginBottom: '10px',
        }}>
        404
      </h1>
      <h1 style={{ textAlign: 'center', fontSize: '28px' }}>Page Not Found</h1>
    </div>
  );
};

export default NotFound;
