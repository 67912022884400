import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '@/screens/_share/NotFound';
import Layout from '@/screens/_share/Layout';
import ContactPage from '@/screens/ContactPage';
import NewsWrapper from '@/screens/news/NewsWrapper';
import KOLsWrapper from '@/screens/kols/KOLsWrapper';
import RecruimentWrapper from '@/screens/recruitment/RecruimentWrapper';
import Homepage from '@/screens/HomePage';

function SwitchScreen() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Homepage />} />
        <Route path="tin-tuc/*" element={<NewsWrapper />} />
        <Route path="kols/*" element={<KOLsWrapper />} />
        <Route path="tuyen-dung/*" element={<RecruimentWrapper />} />
        <Route path="lien-he" element={<ContactPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default SwitchScreen;
