import React from 'react';
import CountUp from 'react-countup';

const About = ({ data }) => {
  return (
    <section className="about">
      <div className="container">
        <section className="about-inner">
          <div className="section-title">
            <h3>Chúng tôi có</h3>
            <span></span>
          </div>
          <div className="about-list counter-show">
            {data.map((about, index) => (
              <ul className="about-item" key={index}>
                <li id="counter-box">
                  <span className="timer count-number">
                    <CountUp start={0} end={about.value} delay={2} />
                  </span>
                  {about.extend}
                </li>
                <li>{about.label}</li>
              </ul>
            ))}
          </div>
        </section>
      </div>
    </section>
  );
};

export default About;
