import Table from '@/components/_share/table/Table';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const RecruitmentPage = () => {
  const columns = useCallback((data) => {
    return data.map((row) => {
      return [
        {
          id: 'position',
          header: 'Vị trí',
          classNameHeader: '',
          classNameCell: 'bold',
          cell: <Link to={row.id}>{row.position}</Link>,
        },
        {
          id: 'dealine',
          header: 'Hạn nộp hồ sơ',
          classNameHeader: '',
          classNameCell: '',
          cell: row.time,
        },
        {
          id: 'place',
          header: 'Địa điểm làm việc',
          classNameHeader: 'desktop',
          classNameCell: 'desktop',
          cell: row.place,
        },
      ];
    });
  }, []);

  const dataTable = {
    data: [
      {
        id: '/tuyen-dung/senior-social-community-leader-n223',
        position: 'Senior Social/Community Leader',
        time: '2023-10-31 23:55:56',
        place: '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM',
      },
      {
        id: '/tuyen-dung/partner-manager-meta-n221',
        position: 'Partner Manager (Meta)',
        time: '2023-07-20 05:05:05',
        place: '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM',
      },
      {
        id: '/tuyen-dung/graphic-desinger-pba-project-n219',
        position: 'Graphic Designer (PBA Project)',
        time: '2023-07-15 11:35:09',
        place: '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM',
      },
      {
        id: '/tuyen-dung/talent-management-gg-live-n213',
        position: 'Talent Management (GG Live)',
        time: '2023-06-30 10:55:14',
        place: '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM',
      },
      {
        id: '/tuyen-dung/content-manager-n212',
        position: 'Senior Content Creator',
        time: '2023-08-31 04:00:56',
        place: '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM',
      },
      {
        id: '/tuyen-dung/digital-marketer-hcm-n206',
        position: 'Digital Marketer (HCM)',
        time: '2023-09-30 23:55:33',
        place: '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM',
      },
      {
        id: '/tuyen-dung/digital-marketer-hcm-n206',
        position: 'Sales Executive',
        time: '2023-09-30 23:55:33',
        place: '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, TP.HCM',
      },

      {
        id: '/tuyen-dung/partner-manager-meta-n221',
        position: 'Video Editor (BILLIARDS POOL)',
        time: '2022-06-30 14:15:20',
        place: '235 Hoàng Cầu, Đống Đa, Hà Nội',
      },
      {
        id: '/tuyen-dung/cham-soc-khach-hang-hn-n144',
        position: 'Chăm sóc khách hàng',
        time: '2023-08-31 23:55:58',
        place: '235 Hoàng Cầu, Trung Liệt, Đống Đa, Hà Nội',
      },
      {
        id: '/tuyen-dung/talent-manager-hcm-n133',
        position: 'Talent Manager (HCM)',
        time: '2023-04-23 18:50:24',
        place:
          '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, Quận 2, TP. Hồ Chí Minh',
      },
      {
        id: '/tuyen-dung/video-editor-n131',
        position: 'Video Editor (HCM)',
        time: '2023-01-31 23:55:56',
        place:
          '19 Thân Văn Nhiếp, P. An Phú, TP. Thủ Đức, Quận 2, TP. Hồ Chí Minh',
      },
    ],
    pagination: {
      page: 1,
      page_size: 10,
      total: 44,
      total_pages: 5,
    },
  };

  return (
    <section className="recruit main-content">
      <div className="container">
        <div className="main-title ">Tuyển dụng</div>
        <Table dataTable={dataTable} columns={columns} usePagination />
      </div>
    </section>
  );
};

export default RecruitmentPage;
