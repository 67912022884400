import Footer from '@/components/_share/Footer';
import Header from '@/components/_share/Header';
import React from 'react';
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <div className="min-vh-100 flex-fill d-flex flex-column justify-content-between">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
