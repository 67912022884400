import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-logo">
              <img
                src={toAbsoluteUrl('/assets/box-studio/logo-w.png')}
                alt="logo-w"
              />
            </div>
            <h3>Công ty cổ phần BOX VIỆT NAM</h3>
          </div>
          <div className="col-md-9">
            <div className="footer-contact">
              <img
                src={toAbsoluteUrl(
                  '/assets/box-studio/footer/organization.svg',
                )}
                alt="organization"
              />
              Trụ sở chính: Tầng 5, tòa nhà số 8 Hoàng Cầu Mới, Trung Liệt, Đống
              Đa, Hà Nội <br />
              Văn phòng Tp.HCM: 19 Thân Văn Nhiếp, Phường An Phú, Thành phố Thủ
              Đức, TP.Hồ Chí Minh
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-contact">
              <img
                src={toAbsoluteUrl('/assets/box-studio/footer/Phone.svg')}
                alt="Phone"
              />
              Hotline: <Link to="tel:0988428815">098 842 8815</Link>
            </div>
            <div className="footer-contact">
              <img
                src={toAbsoluteUrl('/assets/box-studio/footer/mail.svg')}
                alt="mail"
              />
              Email: booking@box.studio
            </div>
          </div>
          <div className="col-md-12">
            <div className="bottom">
              <img
                src={toAbsoluteUrl('/assets/box-studio/footer/copyright.svg')}
                alt="copyright"
              />
              2022 Box Việt Nam
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
