import Banner from '@/components/screens/homepage/Banner';
import About from '@/components/screens/homepage/About';
import React from 'react';
import Influencers from '@/components/screens/homepage/Influencers';
import Social from '@/components/screens/homepage/Social';
import Network from '@/components/screens/homepage/Network';
import Tiktok from '@/components/screens/homepage/Tiktok';
import Sports from '@/components/screens/homepage/Sports';
import Live from '@/components/screens/homepage/Live';
import Partner from '@/components/screens/homepage/Partner';
import News from '@/components/screens/homepage/News';
import { toAbsoluteUrl } from '@/utils/commons';

const Homepage = () => {
  const dataHomePage = {
    banner: [
      toAbsoluteUrl('/assets/box-studio/banner/1.png'),
      toAbsoluteUrl('/assets/box-studio/banner/2.png'),
      toAbsoluteUrl('/assets/box-studio/banner/3.png'),
      toAbsoluteUrl('/assets/box-studio/banner/4.png'),
      toAbsoluteUrl('/assets/box-studio/banner/5.png'),
    ],
    about: [
      { label: 'Influencers/KOLs', value: 100, extend: '+' },
      { label: 'Fanpage/Group Facebook', value: 100, extend: '+' },
      { label: 'Kênh Tiktok', value: 200, extend: '+' },
      {
        label: 'Theo dõi trên Facebook, Tiktok, Youtube',
        value: 50,
        extend: 'M+',
      },
    ],
    influencers: [
      {
        image: toAbsoluteUrl('/assets/box-studio/influencers/1.png'),
        link: 'https://www.tiktok.com/@marianrivera',
      },
      {
        image: toAbsoluteUrl('/assets/box-studio/influencers/2.png'),
        link: 'https://www.tiktok.com/@marianrivera',
      },
      {
        image: toAbsoluteUrl('/assets/box-studio/influencers/3.png'),
        link: 'https://www.tiktok.com/@marianrivera',
      },
      {
        image: toAbsoluteUrl('/assets/box-studio/influencers/4.png'),
        link: 'https://www.tiktok.com/@marianrivera',
      },
      {
        image: toAbsoluteUrl('/assets/box-studio/influencers/5.png'),
        link: 'https://www.tiktok.com/@marianrivera',
      },
      {
        image: toAbsoluteUrl('/assets/box-studio/influencers/6.png'),
        link: 'https://www.tiktok.com/@marianrivera',
      },
    ],
    network: {
      left: [
        {
          url: toAbsoluteUrl('/assets/box-studio/network/1.png'),
          name: 'Chúng ta của sau này',
          follower: '3,5',
        },
        {
          url: toAbsoluteUrl('/assets/box-studio/network/2.png'),
          name: 'Box Girl',
          follower: '2,8',
        },
        {
          url: toAbsoluteUrl('/assets/box-studio/network/3.png'),
          name: 'Hưng Hại Não',
          follower: '1,9',
        },
      ],
      right: [
        {
          url: toAbsoluteUrl('/assets/box-studio/network/4.png'),
          name: 'Oh My Lady',
          follower: '1,1',
        },
        {
          url: toAbsoluteUrl('/assets/box-studio/network/5.png'),
          name: 'Đỉnh Cao',
          follower: '1,1',
        },
        {
          url: toAbsoluteUrl('/assets/box-studio/network/6.png'),
          name: 'Bà Tám Showbiz',
          follower: '1',
        },
      ],
    },
    tiktok: [
      {
        thumnail: toAbsoluteUrl('/assets/box-studio/tiktok/1.png'),
        video: 'https://www.tiktok.com/embed/v2/7071216785449684250',
      },
      {
        thumnail: toAbsoluteUrl('/assets/box-studio/tiktok/2.png'),
        video: 'https://www.tiktok.com/embed/v2/7067396258910014747',
      },
      {
        thumnail: toAbsoluteUrl('/assets/box-studio/tiktok/3.png'),
        video: 'https://www.tiktok.com/embed/v2/7032147908149382427',
      },
      {
        thumnail: toAbsoluteUrl('/assets/box-studio/tiktok/4.png'),
        video: 'https://www.tiktok.com/embed/v2/7068607774934043930',
      },
      {
        thumnail: toAbsoluteUrl('/assets/box-studio/tiktok/5.png'),
        video: 'https://www.tiktok.com/embed/v2/7072220427032005915',
      },
    ],
    sports: [
      {
        name: 'PES Team',
        image: `url(${toAbsoluteUrl('/assets/box-studio/sports/1.png')})`,
      },
      {
        name: 'PUBGm',
        image: `url(${toAbsoluteUrl('/assets/box-studio/sports/2.png')})`,
      },
      {
        name: 'AOV',
        image: `url(${toAbsoluteUrl('/assets/box-studio/sports/3.png')})`,
      },
      {
        name: 'Freefire',
        image: `url(${toAbsoluteUrl('/assets/box-studio/sports/4.png')})`,
      },
      {
        name: 'LOL',
        image: `url(${toAbsoluteUrl('/assets/box-studio/sports/5.png')})`,
      },
    ],
    partner: [
      { name: 'garena', status: 'new' },
      { name: 'tencent', status: 'old' },
      { name: 'NetEaseGames', status: 'new' },
      { name: 'RiotGame', status: 'new' },
      { name: 'VNG', status: 'new' },
      { name: 'cocacola', status: 'old' },
      { name: 'corsair', status: 'new' },
      { name: 'Gigabyte', status: 'new' },
      { name: 'Intel', status: 'new' },
      { name: 'xiaomi', status: 'new' },
      { name: 'Samsung', status: 'new' },
      { name: 'FPT', status: 'new' },
      { name: 'Eucerin', status: 'new' },
      { name: 'Larocheposay', status: 'new' },
      { name: 'Tresemme', status: 'new' },
      { name: 'L_oreal', status: 'new' },
      { name: 'Rohto', status: 'new' },
      { name: 'senka', status: 'new' },
      { name: 'Pepsico', status: 'new' },
      { name: 'Budweiser', status: 'new' },
      { name: 'Heineken', status: 'new' },
      { name: 'cocacola', status: 'old' },
      { name: 'McDonald_s', status: 'new' },
      { name: 'Acecook', status: 'new' },
      { name: 'Adidas', status: 'new' },
      { name: 'GAP', status: 'new' },
      { name: 'Innity', status: 'new' },
      { name: 'Leonardo', status: 'new' },
      { name: 'pedro', status: 'new' },
      { name: 'juno', status: 'new' },
      { name: 'kla', status: 'new' },
      { name: 'Ogilvy', status: 'new' },
      { name: 'Metub', status: 'new' },
      { name: 'Anymind', status: 'new' },
      { name: 'Golden', status: 'new' },
      { name: 'Vccorp', status: 'new' },
    ],
    news: {
      data: [
        {
          id: 'lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022-n119',
          title: 'LCK mùa Xuân 2022: Kịch tính hơn nhờ ASIAD 2022',
          image:
            'https://box.studio/uploads/image/2022/01/13/lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022_1642046900.jpg?v=1646900944',
        },
        {
          id: 'giai-dau-fifa-online-4-lon-nhat-the-gioi-nam-2021-chinh-thuc-khep-lai-dai-dien-viet-nam-dat-top-6-chung-cuoc-n117',
          title:
            'Giải đấu FIFA ONLINE 4 lớn nhất thế giới năm 2021 chính thức khép lại: Đại diện Việt Nam đạt top 6 chung cuộc',
          image:
            'https://box.studio/uploads/image/2022/01/05/giai-dau-fifa-online-4-lon-nhat-the-gioi-nam-2021-chinh-thuc-khep-lai-dai-dien-viet-nam-dat-top-6-chung-cuoc_1641371590.jpg?v=1641371716',
        },
        {
          id: 'lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022-n119',
          title: 'LCK mùa Xuân 2022: Kịch tính hơn nhờ ASIAD 2022',
          image:
            'https://box.studio/uploads/image/2022/01/13/lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022_1642046900.jpg?v=1646900944',
        },
        {
          id: 'lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022-n119',
          title: `'Hiếu Leblanc - 'ông chủ rạp xiếc' của Box Studio: Streamer GenZ chuyên tấu hài mỗi lần lên sóng`,
          image:
            'https://box.studio/uploads/image/2021/11/25/hieu-leblanc-ong-chu-rap-xiec-cua-box-studio-streamer-genz-chuyen-tau-hai-moi-lan-len-song_1637832854.jpg?v=1637832854',
        },
      ],
      ourCredentials:
        'https://drive.google.com/file/d/16b-XyGMV3vPAOLQ3KbC2asFF7uHfFSB_/view',
    },
  };

  return (
    <>
      <Banner data={dataHomePage.banner} />
      <About data={dataHomePage.about} />
      <Influencers data={dataHomePage.influencers} />
      <Social />
      <Network data={dataHomePage.network} />
      <Tiktok data={dataHomePage.tiktok} />
      <Sports data={dataHomePage.sports} />
      <Live />
      <Partner data={dataHomePage.partner} />
      <News data={dataHomePage.news} />
    </>
  );
};

export default Homepage;
