import React from 'react';
import { Link } from 'react-router-dom';

const Influencers = ({ data }) => {
  return (
    <section className="influencers">
      <div className="container">
        <div className="influencers-inner">
          <div className="influencers--content">
            <div className="section-title " data-aos="fade-down">
              <h3>Influencers/KOLs</h3>
              <span></span>
            </div>
            <p className="section-content " data-aos="fade-up">
              Cung cấp{' '}
              <strong> giải pháp Influencer Marketing chuyên nghiệp </strong>và{' '}
              <strong>hiệu quả</strong>. <br />
              Booking quảng cáo với <strong>100+ KOLs/Influencers</strong> thuộc
              nhiều lĩnh vực khác nhau.
            </p>
          </div>
          <div className="influencers--img" data-aos="fade-right">
            <div className="influencers--img--inner">
              {data.map((influencer, index) => (
                <Link to={influencer.link} target="_blank" key={index}>
                  <img
                    src={influencer.image}
                    className={`influencer-${index}`}
                    alt={`influencer-${index}`}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Influencers;
