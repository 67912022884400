import Related from '@/components/_share/Related';
import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';
import { Link } from 'react-router-dom';

const NewsDetailPage = () => {
  return (
    <>
      <section className="detail-section">
        <div className="container">
          <div className="detail-title">
            CEO Box Việt Nam: "Không nhà đầu tư nào lại từ chối ‘miếng bánh’ thể
            thao điện tử"
          </div>
          <div className="detail-time">
            <span>
              <img
                src={toAbsoluteUrl('/assets/box-studio/Calendar.svg')}
                alt="Calendar"
              />
              Thứ bảy, 18/09/2021
            </span>
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{
              __html: `        <p className="font-weight-bold">
            
            Đối mặt với ảnh hưởng của dịch bệnh, ông Bùi Bá Hiến - CEO của Box
            Việt Nam đã chia sẻ những giải pháp của ông cho doanh nghiệp trong
            thời kỳ khó khăn này.
          </p>
          <p>
            Năm 2021, dịch Covid 19 lại bùng phát khiến nền kinh tế bị ảnh
            hưởng, trung bình mỗi tháng có hơn 11 nghìn công ty đóng cửa, tỷ lệ
            người thất nghiệp tại thành thị gia tăng lên hơn 4%. Các start-up
            với nguồn vốn hạn chế cùng dòng tiền thị trường ít lưu động cũng gặp
            nhiều khó khăn.
          </p>
          <p>
            Cùng trò chuyện với ông Bùi Bá Hiến - CEO Công ty Cổ phần Box Việt
            Nam về kế hoạch kinh doanh công ty trong giai đoạn dịch Covid-19 và
            những kỳ vọng trong tương lai.
          </p>
          <p>
            <em>
              - Sự bùng nổ của dịch Covid-19 ảnh hưởng không nhỏ tới nền kinh tế
              chung. Xin ông cho biết hoạt động kinh doanh của Box đã bị tác
              động ra sao?
            </em>
          </p>
          <p>
            Các hoạt động của chúng tôi trong những ngày qua bị trì hoãn khá
            nhiều. Việc giãn cách xã hội khiến các tuyển thủ khó giao tiếp hơn
            khi thi đấu và luyện tập, các influencer cũng bị hạn chế trong không
            gian xây dựng ý tưởng, nhiều đối tác dè dặt trong việc đầu tư,...
          </p>
          <p>
            Trước tình hình đó, chúng tôi cũng đã tập trung tối ưu chi phí, đảm
            bảo duy trì các nguồn doanh thu không bị ảnh hưởng. Mặt khác công ty
            cũng duy trì chế độ cơ bản cho nhân viên để cùng vượt qua giai đoạn
            khó khăn này.
          </p>
          <p>
            <em>
              - Mới đây, thông tin ASIAD 2022 sẽ đưa Esports trở thành bộ môn có
              huy chương, ông có tự tin về việc các đội tuyển của ông có khả
              năng đại diện Việt Nam tham dự ASIAD 2022?
            </em>
          </p>
          <p>
            Thông tin này đã đem lại nhiều cơ hội hơn cho chúng tôi. Box Việt
            Nam luôn khuyến khích tập trung đầu tư để rèn luyện lớp trẻ song
            song với việc thúc đẩy năng lực của lớp tuyển thủ đi trước.
          </p>
          <p>
            Với tiềm năng và kinh nghiệm như vậy, tôi tin tưởng những đội tuyển
            của Box có đủ thực lực để tranh huy chương tại ASIAD 2022 trong
            tương lai.
          </p>
          <p>
            <em>
              - Những thông tin này liệu có khiến các nhà đầu tư quan tâm hơn
              đến thị trường thể thao điện tử không?
            </em>
          </p>
          <p>
            Các yếu tố như dịch bệnh hay ASIAD 2022 khiến mọi người tò mò và tìm
            đến những bộ môn giải trí online nhiều hơn, thể thao điện tử đáp ứng
            điều đó.
          </p>
          <p>
            Nhu cầu giải trí số tăng cao, đồng nghĩa với nhiều tiềm năng truyền
            thông hơn. Không một nhà đầu tư thông minh nào lại từ chối "miếng
            bánh" lớn như thế cả.
          </p>
          <p>
            Box Việt Nam rất muốn hợp tác với những nhà đầu tư như vậy. Hiện
            tại, chúng tôi cũng đang trong quá trình gọi vốn cho một số dự án
            quan trọng, hiện tại quá trình này đã thành công 90%.
          </p>
          <p id="slot-1-480458-jzjm4ebw"></p>
          <p
            id="banner-480458-jzjm4ebw"
            style="min-height: 0px; min-width: 0px;"></p>
          <p id="placement-jzjm4ebj"></p>
          <p id="share-jtgyri8j"></p>
          <p id="zone-480458"></p>
          <p id="admzone480458" className="wp100 fl mgt10"></p>
          <p>
            <em>
              - Box Việt Nam sẽ sử dụng dòng tiền cho màn gọi vốn này thế nào?
            </em>
          </p>
          <p>
            Chúng tôi có những kế hoạch và dự án lớn, đa phần nguồn vốn của
            chúng tôi kêu gọi được thành công đều để phục vụ cho những dự án
            này. Hiện tại tôi chưa thể tiết lộ được gì nhiều nhưng đó là những
            dự án về công nghệ giải trí gắn với lĩnh vực Gaming - vốn là sở
            trường của Box Việt Nam. Chúng tôi vẫn sẽ không ngừng gọi thêm vốn
            dựa theo các lộ trình phát triển đã có.
          </p>
          <p>
            <img
              id="img_47b97510-16de-11ec-b6f3-41b354c48c84"
              style="max-width: 100%; display: block; margin-left: auto; margin-right: auto;"
              title="CEO Box Việt Nam: Không nhà đầu tư nào lại từ chối ‘miếng bánh’ thể thao điện tử - Ảnh 1."
              src="https://channel.mediacdn.vn/thumb_w/640/2021/9/16/photo-1-16317904476501355586621.jpg"
              alt="CEO Box Việt Nam: Không nhà đầu tư nào lại từ chối ‘miếng bánh’ thể thao điện tử - Ảnh 1."
              width=""
              height=""
              data-original="https://channel.mediacdn.vn/2021/9/16/photo-1-16317904476501355586621.jpg"
            />
          </p>
          <p>
            <em>- Ông có dự định gọi thêm vốn trong năm 2022?</em>
          </p>
          <p>
            Tính đến thời điểm hiện tại, Box Việt Nam đã tích lũy đủ để gọi vốn
            và sử dụng vốn một cách hợp lý. Tuy nhiên, gọi vốn luôn phải cẩn
            trọng với nhiều yếu tố. Nhiều start-up thất bại bởi chưa có đánh giá
            đúng về bản thân và gọi vốn bừa bãi.
          </p>
          <p>
            Vậy nên, tuỳ vào diễn biến thị trường và các ý tưởng hình thành mới,
            chúng tôi mới tính tới việc góp thêm vốn hoặc huy động thêm trong
            năm 2022.
          </p>
          <p>
            <em>
              - Không chỉ là một tổ chức thể thao điện tử có nhiều đội tuyển nổi
              tiếng mà "hệ sinh thái" của Box Việt Nam còn có rất nhiều
              influencer được giới trẻ yêu thích đúng không?
            </em>
          </p>
          <p>
            Tôi chưa thể nói đây là một "hệ sinh thái", chỉ có thể coi là những
            bước đầu trong mô hình đó. Các influencer có định hướng phát triển
            khác biệt so với các tuyển thủ thể thao điện tử, nhưng lại có sự
            giao thoa để hỗ trợ lẫn nhau. Nhưng đó chỉ là sự phối hợp sơ khai
            nhất và còn rất nhiều thứ để làm phía trước.
          </p>
          <p>
            Mọi người thường cho rằng những game thủ chuyên nghiệp hay streamer
            là công việc có tuổi đời ngắn ngủi, tôi muốn thay đổi điều đó. Dù
            tuổi đời thế nào đi chăng nữa, Box Việt Nam vẫn luôn giúp đỡ mọi
            người gắn bó và ổn định với thể thao điện tử, đó chính là ước mơ của
            tôi.
          </p>
          <p>
            <em>
              * Cảm ơn ông về những chia sẻ, chúc Box Việt Nam ngày càng phát
              triển hơn.
            </em>
          </p>
          <p>
            Công ty Cổ phần Box Việt Nam được thành lập vào năm 2017. Sau hơn 4
            năm phát triển, Box Việt Nam trở thành một trong những đơn vị tiên
            phong trên thị trường Esports Việt Nam khi sở hữu những đội tuyển
            hàng đầu tại nhiều bộ môn với tên gọi Box Gaming. Đồng thời cũng đào
            tạo nhiều streamer tên tuổi trong làng game Việt như Quang Cuốn,
            Thảo Nari, Mèo Sao Hỏa, Hà Tiều Phu, Duy Còm, Gấm Kami, Quỳnh
            Alee,...
          </p>
          <p style="text-align: right;">
            <strong>
              <em>Theo: CafeF</em>
            </strong>
          </p>
          <p>
            <span id="mainContent"></span>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>`,
            }}></div>

          <div className="detail-share">
            Chia sẻ:
            <Link
              to="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbox.studio%2Ftin-tuc%2Fceo-box-viet-nam-khong-nha-dau-tu-nao-lai-tu-choi-mieng-banh-the-thao-dien-tu-n111"
              target="_blank">
              <img
                src={toAbsoluteUrl('/assets/box-studio/social/Facebook.svg')}
                alt="Facebook"
              />
            </Link>
          </div>
        </div>
      </section>
      <Related />
    </>
  );
};

export default NewsDetailPage;
