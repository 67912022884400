import React from 'react';
import { Link } from 'react-router-dom';

const News = ({ data }) => {
  return (
    <section className="news">
      <div className="container">
        <div className="section-title " data-aos="fade-down">
          <Link to="/tin-tuc">
            <h3>Tin tức</h3>
          </Link>
          <span></span>
        </div>
        <div className="row " data-aos="fade-up">
          {data.data.map((item, index) => (
            <div className="col-md-3" key={index}>
              <Link to={`/tin-tuc/${item.id}`} className="news-item">
                <div className="news-img">
                  <img src={item.image} alt="news" />
                </div>
                <h5>{item.title}</h5>
              </Link>
            </div>
          ))}
        </div>
        <div className="section-title " data-aos="fade-down">
          <Link target="_blank" to={data.ourCredentials}>
            <h3>Our Credentials</h3>
          </Link>
          <span></span>
        </div>
      </div>
    </section>
  );
};

export default News;
