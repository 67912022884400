import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';
import { Link } from 'react-router-dom';

const ContactPage = () => {
  return (
    <section className="contact main-content">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="contact-title">Liên hệ</div>
            <h1 className="company">Công ty cổ phần BOX VIỆT NAM</h1>
            <ul>
              <li>
                <img
                  src={toAbsoluteUrl(
                    '/assets/box-studio/footer/organization.svg',
                  )}
                  alt="contact-1"
                />
                <span>
                  Trụ sở chính: Tầng 5, tòa nhà số 8 Hoàng Cầu Mới, Trung Liệt,
                  Đống Đa, Hà Nội <br />
                  Văn phòng Tp. HCM: 19 Thân Văn Nhiếp, Phường An Phú, Thành phố
                  Thủ Đức, TP.Hồ Chí Minh
                </span>
              </li>
              <li>
                <img
                  src={toAbsoluteUrl('/assets/box-studio/footer/Phone.svg')}
                  alt="contact-2"
                />

                <span>
                  Hotline: <Link to="tel:0888835715"> 0888 835 715</Link>
                </span>
              </li>
              <li>
                <img
                  src={toAbsoluteUrl('/assets/box-studio/footer/mail.svg')}
                  alt="contact-3"
                />
                <span>Email: booking@box.studio</span>
              </li>
            </ul>
          </div>
          <div className="col-md-7">
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.4609032727794!2d105.81721021533205!3d21.01423639364884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab8da54f7dfb%3A0xd44e1a159b97a89f!2sBox%20Studio!5e0!3m2!1svi!2s!4v1625043371071!5m2!1svi!2s"
                width="100%"
                height="500"
                style={{ border: 0 }}
                loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
