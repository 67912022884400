import React, { useMemo } from 'react';
import Select from 'react-select';

const pageStyles = {
  control: (styles, { hasValue, isFocused }) => {
    return {
      ...styles,
      boxShadow: 'none',
      borderColor: isFocused && hasValue ? '#ef5f21' : '#e5e6ec',
      '&:hover': {
        borderColor: isFocused && hasValue ? '#ef5f21' : '#e5e6ec',
      },
    };
  },
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      cursor: 'pointer',
      background: isSelected && '#ef5f21',
      boxShadow: 'none',
      '&:hover': {
        background: !isSelected && '#ffc0a59e',
      },
    };
  },
};

const PageSizeTable = ({ event, pageSize }) => {
  const pageSizes = useMemo(
    () => [
      {
        value: 10,
        label: '10',
      },
      {
        value: 25,
        label: '25',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 100,
        label: '100',
      },
      {
        value: 200,
        label: '200',
      },
    ],
    [],
  );

  return (
    <div>
      <Select
        isSearchable={false}
        isMulti={false}
        options={pageSizes}
        styles={pageStyles}
        value={{
          value: pageSize,
          label: pageSize,
        }}
        onChange={(value) => event(value)}
      />
    </div>
  );
};

export { PageSizeTable };
