import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';

const Social = () => {
  return (
    <section className="social">
      <div className="social-inner">
        <div className="container">
          <div className="section-title " data-aos="fade-down">
            <h3>Sản xuất nội dung đa kênh &amp; đa dạng thể loại</h3>
            <span></span>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="social-img " data-aos="fade-left">
                <img
                  src={toAbsoluteUrl('/assets/box-studio/social/1.png')}
                  alt="social1"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="social-img center " data-aos="fade-up">
                <img
                  src={toAbsoluteUrl('/assets/box-studio/social/2.png')}
                  alt="social2"
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="social-img " data-aos="fade-right">
                <img
                  src={toAbsoluteUrl('/assets/box-studio/social/3.png')}
                  alt="social3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Social;
