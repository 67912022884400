import classNames from 'classnames';
import React from 'react';
import { PaginateRange } from './PaginateRange';
import useWindowDimensions from '@/hooks/useWindowDimensions';

const PaginationTable = ({ onPageChange, className, pagination }) => {
  const { height, width } = useWindowDimensions();
  const paginationRange = PaginateRange({
    pagination,
    pageNumber: height < 768 || width < 768 ? 1 : 2,
  });
  const currentPage = pagination.page;
  const lastPage = pagination.total_pages;

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const onLast = () => {
    if (currentPage !== lastPage) {
      onPageChange(lastPage);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  };

  const onFirst = () => {
    if (currentPage !== 1) {
      onPageChange(1);
    }
  };

  return (
    <ul className={classNames('pagination m-0', className)}>
      <li
        key={'first-page'}
        className={classNames('page-item previous', {
          disabled: currentPage === 1,
          'cursor-no-drop': currentPage === 1,
        })}
        onClick={() => onFirst()}>
        <button className="page-link">««</button>
      </li>
      <li
        key={'previous-page'}
        className={classNames('page-item previous', {
          disabled: currentPage === 1,
          'cursor-no-drop': currentPage === 1,
        })}
        onClick={() => onPrevious()}>
        <button className="page-link">«</button>
      </li>
      {paginationRange.map((pageNumber) => {
        return (
          <li
            key={pageNumber}
            className={classNames('page-item', {
              active: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}>
            <button className="page-link">{pageNumber}</button>
          </li>
        );
      })}
      <li
        key={'next-page'}
        className={classNames('page-item next', {
          disabled: currentPage === lastPage,
          'cursor-no-drop': currentPage === lastPage,
        })}
        onClick={() => onNext()}>
        <button className="page-link">»</button>
      </li>
      <li
        key={'last-page'}
        className={classNames('page-item next', {
          disabled: currentPage === lastPage,
          'cursor-no-drop': currentPage === lastPage,
        })}
        onClick={() => onLast()}>
        <button className="page-link">»»</button>
      </li>
    </ul>
  );
};

export { PaginationTable };
