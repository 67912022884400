import React from 'react';
import { Link } from 'react-router-dom';

const Related = () => {
  return (
    <section className="related">
      <div className="container">
        <div className="related-title">CÓ THỂ BẠN QUAN TÂM</div>
        <div className="row">
          <div className="col-md-3">
            <Link
              to="/tin-tuc/lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022-n119"
              className="news-item">
              <div className="news-img">
                <img
                  src="https://box.studio/uploads/image/2022/01/13/lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022_1642046900.jpg?v=1646900944"
                  alt=""
                />
              </div>
              <h5>LCK mùa Xuân 2022: Kịch tính hơn nhờ ASIAD 2022</h5>
            </Link>
          </div>

          <div className="col-md-3">
            <Link
              to="/tin-tuc/giai-dau-fifa-online-4-lon-nhat-the-gioi-nam-2021-chinh-thuc-khep-lai-dai-dien-viet-nam-dat-top-6-chung-cuoc-n117"
              className="news-item">
              <div className="news-img">
                <img
                  src="https://box.studio/uploads/image/2022/01/05/giai-dau-fifa-online-4-lon-nhat-the-gioi-nam-2021-chinh-thuc-khep-lai-dai-dien-viet-nam-dat-top-6-chung-cuoc_1641371590.jpg?v=1641371716"
                  alt=""
                />
              </div>
              <h5>
                Giải đấu FIFA ONLINE 4 lớn nhất thế giới năm 2021 chính thức
                khép lại: Đại diện Việt Nam đạt top 6 chung cuộc
              </h5>
            </Link>
          </div>

          <div className="col-md-3">
            <Link
              to="/tin-tuc/blv-van-tung-so-voi-sn-cua-nam-2020-edg-co-xuat-phat-diem-tot-hon-n115"
              className="news-item">
              <div className="news-img">
                <img
                  src="https://box.studio/uploads/image/2021/11/25/blv-van-tung-so-voi-sn-cua-nam-2020-edg-co-xuat-phat-diem-tot-hon_1637832395.jpg?v=1637832395"
                  alt=""
                />
              </div>
              <h5>
                BLV Văn Tùng: 'So với SN của năm 2020, EDG có xuất phát điểm tốt
                hơn'
              </h5>
            </Link>
          </div>

          <div className="col-md-3">
            <Link
              to="/tin-tuc/hieu-leblanc-ong-chu-rap-xiec-cua-box-studio-streamer-genz-chuyen-tau-hai-moi-lan-len-song-n116"
              className="news-item">
              <div className="news-img">
                <img
                  src="https://box.studio/uploads/image/2021/11/25/hieu-leblanc-ong-chu-rap-xiec-cua-box-studio-streamer-genz-chuyen-tau-hai-moi-lan-len-song_1637832854.jpg?v=1637832854"
                  alt=""
                />
              </div>
              <h5>
                Hiếu Leblanc - 'ông chủ rạp xiếc' của Box Studio: Streamer GenZ
                chuyên tấu hài mỗi lần lên sóng
              </h5>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Related;
