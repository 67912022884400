import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';

const Sports = ({ data }) => {
  return (
    <section className="sport">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="section-title " data-aos="fade-down">
              <h3>Box Sports</h3>
              <span></span>
            </div>
          </div>
          <div className="col-md-8 " data-aos="fade-up">
            <p className="section-content">
              Box Sports là thành viên chính thức của Ban Thường vụ Hội Thể thao
              điện tử giải trí Việt Nam (VIRESA)
            </p>
            <ul className="level-1">
              <li>
                {' '}
                <strong> Chuyên quản lý </strong> vận động viên thể thao, tuyển
                thủ thể thao điện tử
              </li>
              <li>
                <strong> Sản xuất và khai thác </strong> doanh thu từ nội dung
                eSports{' '}
              </li>
              <li>
                <strong>Tổ chức sự kiện, giải đấu</strong> thể thao điện tử
                trong và ngoài nước
              </li>
            </ul>
          </div>
        </div>
        <div className="sport-list">
          {data.map((item, index) => (
            <div
              key={index}
              className="sport-item"
              style={{
                backgroundImage: item.image,
              }}
              data-aos="fade-up"
              data-aos-delay={300 + index * 50}>
              <div className="sport-inner">
                <h3>
                  Box Gaming <br />
                  {item.name}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="sport-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="sport-title " data-aos="fade-down">
                Đối tác độc quyền của LCK tại <br />
                Việt Nam
              </div>
              <p className="section-content " data-aos="fade-up">
                Từ năm 2021, Box Việt Nam sở hữu độc quyền phát sóng giải LCK
                tại Việt Nam. Đây là giải đấu
                <strong>Liên Minh Huyền Thoại chuyên nghiệp</strong> từ Hàn Quốc
                với tổng giá trị giải thưởng lên đến gần 6 tỷ đồng.
              </p>
            </div>
            <div className="col-md-7">
              <div className="right " data-aos="fade-right">
                <img
                  src={toAbsoluteUrl('/assets/box-studio/sports/lck.png')}
                  alt="sports"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sports;
