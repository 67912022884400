import { PaginationTable } from '@/components/_share/table/PaginationTable';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

const settingsHeader = {
  className: 'mobile-news slick-dotted',
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
};

const dataTop = [
  {
    url: '/tin-tuc/1',
    img: 'https://box.studio/uploads/image/2022/01/13/lck-mua-xuan-2022-kich-tinh-hon-nho-asiad-2022_1642046900.jpg?v=1646900944',
    title: 'LCK mùa Xuân 2022: Kịch tính hơn nhờ ASIAD 2022',
    description: `Giải đấu LCK Mùa Xuân sắp tới sẽ là giải đấu có sự ảnh hưởng lớn
                tới việc lựa chọn thành viên đội tuyển Hàn Quốc đi tham dự ASIAD
                20022 vào tháng 9 tới, đây sẽ là hy vọng để các tuyển thủ này
                đoạt huy chương, có cơ hội được miễn nghĩa vụ quân sự.`,
  },
  {
    url: '/tin-tuc/2',
    img: 'https://box.studio/uploads/image/2022/01/05/giai-dau-fifa-online-4-lon-nhat-the-gioi-nam-2021-chinh-thuc-khep-lai-dai-dien-viet-nam-dat-top-6-chung-cuoc_1641371590.jpg?v=1641371716',
    title: `Giải đấu FIFA ONLINE 4 lớn nhất thế giới năm 2021 chính thức khép lại: Đại diện Việt Nam đạt top 6 chung cuộc`,
    description: `Giải đấu LCK Mùa Xuân sắp tới sẽ là giải đấu có sự ảnh hưởng lớn
                tới việc lựa chọn thành viên đội tuyển Hàn Quốc đi tham dự ASIAD
                20022 vào tháng 9 tới, đây sẽ là hy vọng để các tuyển thủ này
                đoạt huy chương, có cơ hội được miễn nghĩa vụ quân sự.`,
  },
  {
    url: '/tin-tuc/3',
    img: 'https://box.studio/uploads/image/2021/11/25/blv-van-tung-so-voi-sn-cua-nam-2020-edg-co-xuat-phat-diem-tot-hon_1637832395.jpg?v=1637832395',
    title: `BLV Văn Tùng: 'So với SN của năm 2020, EDG có xuất phát điểm tốt hơn'`,
    description: `Giải đấu LCK Mùa Xuân sắp tới sẽ là giải đấu có sự ảnh hưởng lớn
                tới việc lựa chọn thành viên đội tuyển Hàn Quốc đi tham dự ASIAD
                20022 vào tháng 9 tới, đây sẽ là hy vọng để các tuyển thủ này
                đoạt huy chương, có cơ hội được miễn nghĩa vụ quân sự.`,
  },
  {
    url: '/tin-tuc/4',
    img: 'https://box.studio/uploads/image/2021/11/25/hieu-leblanc-ong-chu-rap-xiec-cua-box-studio-streamer-genz-chuyen-tau-hai-moi-lan-len-song_1637832854.jpg?v=1637832854',
    title: `Hiếu Leblanc - 'ông chủ rạp xiếc' của Box Studio: Streamer GenZ chuyên tấu hài mỗi lần lên sóng`,
    description: `Giải đấu LCK Mùa Xuân sắp tới sẽ là giải đấu có sự ảnh hưởng lớn
                tới việc lựa chọn thành viên đội tuyển Hàn Quốc đi tham dự ASIAD
                20022 vào tháng 9 tới, đây sẽ là hy vọng để các tuyển thủ này
                đoạt huy chương, có cơ hội được miễn nghĩa vụ quân sự.`,
  },
];

const dataNews = {
  data: [
    {
      url: '/tin-tuc/5',
      img: 'https://box.studio/uploads/image/2021/09/19/ceo-box-viet-nam-khong-nha-dau-tu-nao-lai-tu-choi-mieng-banh-the-thao-dien-tu_1632020870.jpg?v=1646989978',
      time: 'Thứ bảy, 18/09/2021',
      title: `CEO Box Việt Nam: "Không nhà đầu tư nào lại từ chối ‘miếng bánh’ thể thao điện tử`,
      description: `Gắn bó với Liên Minh Huyền Thoại (LMHT) từ khi còn sinh viên, Lê Văn Tùng từng bước trở thành bình luận viên (BLV), phân tích viên và cố vấn cho đội eSports ở Hàn Quốc.`,
    },
    {
      url: '/tin-tuc/6',
      img: 'https://box.studio/uploads/image/2021/09/19/chang-trai-viet-thanh-co-van-cho-doi-esports-han-quoc_1632021504.jpg?v=1632021614',
      time: 'Thứ bảy, 18/09/2021',
      title: `Chàng trai Việt thành cố vấn cho đội eSports Hàn Quốc`,
      description: `Gắn bó với Liên Minh Huyền Thoại (LMHT) từ khi còn sinh viên, Lê Văn Tùng từng bước trở thành bình luận viên (BLV), phân tích viên và cố vấn cho đội eSports ở Hàn Quốc.`,
    },
    {
      url: '/tin-tuc/7',
      img: 'https://box.studio/uploads/image/2021/09/19/phong-van-ceo-box-viet-nam-chung-toi-ky-vong-se-dua-cac-ngoi-sao-nhu-showmaker-chovy-den-voi-khan-gia-viet-vao-nam-toi_1632020534.jpg?v=1632020674',
      time: 'Thứ bảy, 17/09/2021',
      title: `Phỏng vấn CEO Box Việt Nam: "Chúng tôi kỳ vọng sẽ đưa các ngôi sao như ShowMaker, Chovy đến với khán giả Việt vào năm tới"`,
      description: ``,
    },
    {
      url: '/tin-tuc/6',
      img: 'https://box.studio/uploads/image/2021/09/08/dan-dat-box-pes-gaming-gianh-lien-tiep-2-chuc-vo-dich-thai-lan-quan-bi-khiem-ton-chi-dat-2-4-muc-tieu_1646975543.jpg?v=1646975543',
      time: 'Thứ tư, 08/09/2021',
      title: ` Dẫn dắt BOX PES Gaming giành liên tiếp 2 chức vô địch Thái Lan, Quân Bi khiếm tốn: 'Chỉ đạt 2/4 mục tiêu' `,
      description: ` 'Huyền thoại làng PES Việt Nam' Quân Bi đã có những chia sẻ sau khi cùng đồng đội đại thắng giải PES Thái Lan và nhận về số tiền 'khủng' 10.000 USD (gần 228 triệu đồng). `,
    },
    {
      url: '/tin-tuc/7',
      img: 'https://box.studio/uploads/image/2021/09/19/lck-mua-he-2021-kenh-ban-quyen-tieng-viet-vuot-muc-thanh-cong-box-viet-nam-thiet-lap-chuan-muc-moi_1632021066.jpg?v=1632021116',
      time: 'Thứ bảy, 18/09/2021',
      title: `LCK mùa Hè 2021: Kênh bản quyền tiếng Việt vượt mức thành công, Box Việt Nam thiết lập chuẩn mực mới`,
      description: ` Với những con số người xem trực tuyến và lượt xem tổng còn từng có thời điểm vượt qua cả kênh chiếu quốc tế song song, sự thành công của kênh LCK Tiếng Việt đã khiến không ít nhà đầu tư “đỏ mắt”, dự đoán xu hướng mua bản quyền các giải đấu thể thao điện tử sẽ nở rộ trong thời gian tới, nhất là trong bối cảnh chịu ảnh hưởng của dịch bệnh. `,
    },
  ],
  pagination: {
    page: 1,
    page_size: 10,
    total: 46,
    total_pages: 5,
  },
};

const NewsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (page) => {
    dataNews.pagination.page = page;
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  return (
    <section className="main-content">
      <div className="container">
        <div className="main-title"> Tin tức</div>
        <div className="row desktop">
          <div className="col-md-6">
            <div className="detail-news detail-news-left">
              <Link to={dataTop[0].url} className="img">
                <img src={dataTop[0].img} alt={dataTop[0].url} />
              </Link>
              <Link to={dataTop[0].url}>
                <h5>{dataTop[0].title}</h5>
              </Link>
              <p>{dataTop[0].description}</p>
            </div>
          </div>
          <div className="col-md-6">
            {dataTop?.slice(1, 4)?.map((item, index) => (
              <div className="detail-news" key={index}>
                <div className="row">
                  <div className="col-md-4">
                    <Link to={item.url} className="img">
                      <img src={item.img} alt={item.url} />
                    </Link>
                  </div>
                  <div className="col-md-8">
                    <Link to={item.url}>
                      <h5>{item.title}</h5>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mobile">
          <Slider {...settingsHeader}>
            {dataTop.map((item, index) => (
              <div
                key={index}
                className="detail-news"
                style={{ width: '100%', display: 'inline-block' }}>
                <Link to={item.url} className="img">
                  <img src={item.img} alt={item.url} />
                </Link>
                <Link to={item.url}>
                  <h5>{item.title}</h5>
                </Link>
                <p>{item.description}</p>
              </div>
            ))}
          </Slider>
        </div>
        <div className="news-list">
          {dataNews.data.map((item, index) => (
            <div className="detail-news" key={index}>
              <div className="row">
                <div className="col-md-3">
                  <Link to={item.url} className="img">
                    <img src={item.img} alt={item.url} />
                  </Link>
                </div>
                <div className="col-md-9">
                  <Link to={item.url}>
                    <h5>{item.title}</h5>
                  </Link>
                  <p className="time">{item.time}</p>
                  <p className="des">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center align-items-center my-4">
          <PaginationTable
            onPageChange={(page) => handlePageChange(page)}
            pagination={dataNews.pagination}
          />
        </div>
      </div>
    </section>
  );
};

export default NewsPage;
