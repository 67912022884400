import { toAbsoluteUrl } from '@/utils/commons';
import React, { useState } from 'react';
import Slider from 'react-slick';
import TikTokModal from './Modals/TikTokModal';

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      onClick={onClick}
      className={className}
      src={toAbsoluteUrl('/assets/box-studio/left.svg')}
      style={{ ...style }}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      onClick={onClick}
      className={className}
      src={toAbsoluteUrl('/assets/box-studio/right.svg')}
      style={{ ...style }}
    />
  );
}

const settingsHeader = {
  className: 'tiktok-list slick-dotted',
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '90px',
  pauseOnHover: true,

  responsive: [
    {
      breakpoint: 3000,
      settings: {
        centerPadding: '160px',
      },
    },
    {
      breakpoint: 1280,
      settings: {
        centerPadding: '90px',
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Tiktok = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState('');

  const handlePlayTikTok = (urlFrame) => {
    setDataModal(urlFrame);
    setShowModal(true);
  };

  const handleCloseModalTikTok = () => {
    setShowModal(false);
    setDataModal('');
  };

  return (
    <>
      {showModal && (
        <TikTokModal
          show={showModal}
          handleClose={handleCloseModalTikTok}
          dataModal={dataModal}
        />
      )}
      <section className="tiktok">
        <img
          className="bg-1"
          src={toAbsoluteUrl('/assets/box-studio/bg/bg-2.png')}
          alt="bg-2"
        />
        <img
          className="bg-2"
          src={toAbsoluteUrl('/assets/box-studio/bg/bg-2.png')}
          alt="bg-2"
        />
        <img className="bg-3" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-5">
              <div className="section-title " data-aos="fade-down">
                <h3>TikTok MCN</h3>
                <span></span>
              </div>
              <p className="section-content " data-aos="fade-up">
                Box Việt Nam là đối tác MCN (Multi Channel Network) của TikTok
                tại Việt Nam.
              </p>

              <ul className="level-1 " data-aos="fade-up">
                <li>
                  <strong> Booking quảng cáo</strong> với 200+ Creator MCN độc
                  quyền
                </li>
                <li>
                  Hỗ trợ phát triển kênh TikTok cho cá nhân/doanh nghiệp:
                  <ul className="level-2">
                    <li>
                      <strong>Tư vấn nội dung</strong> video TikTok
                    </li>
                    <li>
                      <strong>Boost video/live stream</strong> lên đề xuất
                    </li>
                    <li>
                      <strong>Mở tính năng mới</strong> trên TikTok
                    </li>
                    <li>
                      <strong>Chạy quảng cáo</strong> TikTok
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-md-6 " data-aos="fade-right">
              <Slider {...settingsHeader}>
                {data.map((item, index) => (
                  <div
                    key={index}
                    className="tiktok-item"
                    style={{ width: '100%', display: 'inline-block' }}>
                    <div
                      className="play"
                      onClick={() => handlePlayTikTok(item.video)}>
                      <img
                        src={toAbsoluteUrl('/assets/box-studio/play.svg')}
                        alt=""
                      />
                    </div>
                    <img className="thumb" src={item.thumnail} alt="tiktok" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tiktok;
