import { toAbsoluteUrl } from '@/utils/commons';
import React from 'react';

const Live = () => {
  return (
    <section className="live">
      <img
        className="bg-1"
        src={toAbsoluteUrl('/assets/box-studio/bg/bg-2.png')}
        alt="bg-1"
      />
      <img
        className="bg-2"
        src={toAbsoluteUrl('/assets/box-studio/bg/bg-2.png')}
        alt="bg-2"
      />

      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="section-title " data-aos="fade-down">
              <h3>GG Live</h3>
              <span></span>
            </div>
            <p className="section-content " data-aos="fade-up">
              GG Live là <strong>mạng xã hội live stream</strong> về game,
              eSports và giải trí.
            </p>
            <ul className="level-1 " data-aos="fade-up">
              <li>
                {' '}
                Với những chương trình live stream mang chất riêng độc đáo, GG
                Live mang đến một bữa tiệc giải trí đầy sôi động và vui vẻ.{' '}
              </li>
              <li>
                Cùng những chính sách hỗ trợ hấp dẫn dành riêng cho streamer và
                idol, GG Live còn là bệ phóng vững chắc cho những bạn trẻ tài
                năng, khát khao tỏa sáng trong ngành giải trí trực tuyến.
              </li>
            </ul>
          </div>
          <div className="col-md-5">
            <div className="live-img " data-aos="fade-right">
              <img
                src={toAbsoluteUrl('/assets/box-studio/gglive/1.png')}
                alt="live"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Live;
