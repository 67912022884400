import { BrowserRouter } from 'react-router-dom';
import SwitchScreen from './router';
import React, { useEffect } from 'react';
import { StateProvider } from './store/context/StateContext';
import reducer, { initialState } from './store/context/StateReducers';
import AOS from 'aos';
import 'aos/dist/aos.css';

const { PUBLIC_URL } = process.env;

function App() {
  useEffect(() => {
    AOS.init({
      useClassNames: 'main main-ani',
      duration: 1000,
      easing: 'ease-in-out',
      delay: 100,
      once: true,
    });
  }, []);

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <BrowserRouter basename={PUBLIC_URL}>
        <SwitchScreen />
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
