import Table from '@/components/_share/table/Table';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const KOLsPage = () => {
  const columns = useCallback((data) => {
    return data.map((row) => {
      return [
        {
          id: 'name',
          header: 'Tên Kênh',
          classNameHeader: '',
          classNameCell: 'bold',
          cell: <Link to={row.id}>{row.name}</Link>,
        },
        {
          id: 'follower',
          header: 'Lượt theo dõi',
          classNameHeader: '',
          classNameCell: '',
          cell: row.follower,
        },
        {
          id: 'introduce',
          header: 'Giới thiệu',
          classNameHeader: 'desktop',
          classNameCell: 'desktop',
          cell: row.introduce,
        },
      ];
    });
  }, []);

  const dataTable = {
    data: [
      {
        id: '/kols/chung-ta-cua-sau-nay',
        name: 'Chúng ta của sau này',
        follower: '3,5 triệu',
        introduce: 'Chuyên về nội dung tình cảm',
      },
      {
        id: '/kols/chung-ta-cua-sau-nay',
        name: 'Chúng ta của sau này',
        follower: '3,5 triệu',
        introduce: 'Chuyên về nội dung tình cảm',
      },
      {
        id: '/kols/chung-ta-cua-sau-nay',
        name: 'Box girl',
        follower: '2,8 triệu',
        introduce: 'Chuyên về nội dung con gái',
      },
      {
        id: '/kols/hung-hai-nao',
        name: 'Hưng hại não',
        follower: '1,9 triệu',
        introduce: 'Chuyên về nội dung hài hước',
      },
      {
        id: '/kols/oh-my-lady',
        name: 'Oh my lady',
        follower: '1,1 triệu',
        introduce: 'Chuyên về nội dung tình cảm',
      },
      {
        id: '/kols/dinh-cao',
        name: 'Đỉnh cao',
        follower: '1,1 triệu',
        introduce: 'Chuyên về nội dung tình cảm',
      },
      {
        id: '/kols/show-biz',
        name: ' Bà tám showbiz',
        follower: '1 triệu',
        introduce: 'Chuyên về nội dung showbiz',
      },
    ],
    pagination: {
      page: 1,
      page_size: 10,
      total: 47,
      total_pages: 5,
    },
  };

  return (
    <section className="recruit main-content">
      <div className="container">
        <div className="main-title">KOLs</div>
        <Table dataTable={dataTable} columns={columns} usePagination />
      </div>
    </section>
  );
};

export default KOLsPage;
