import NewsDetailPage from '@/components/screens/news/NewsDetailPage';
import NewsPage from '@/components/screens/news/NewsPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const NewsWrapper = () => {
  return (
    <Routes>
      <Route path="" element={<NewsPage />} />
      <Route path=":id" element={<NewsDetailPage />} />
    </Routes>
  );
};

export default NewsWrapper;
