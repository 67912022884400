import { isActive, toAbsoluteUrl } from '@/utils/commons';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [isShowMenu, setIsShowMenu] = useState(false);

  return (
    <header className="header">
      <div className={classNames('header-inner', { active: isShowMenu })}>
        <div className="container">
          <div
            className="bg-menu justify-content-center align-items-center"
            onClick={() => setIsShowMenu(!isShowMenu)}>
            <img
              className="img-menu"
              src={toAbsoluteUrl('/assets/box-studio/menu.png')}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Link to="/">
              <img
                className="logo"
                src={toAbsoluteUrl('/assets/box-studio/logo-text.png')}
                alt="box studio"
              />
            </Link>
            <ul
              className={classNames('ms-auto list-page', {
                active: isShowMenu,
              })}>
              <li
                className={classNames({
                  activePage: isActive('/', pathname, true),
                })}
                onClick={() => setIsShowMenu(false)}>
                <Link to="/">Giới thiệu</Link>
              </li>
              <li
                className={classNames({
                  activePage: isActive('/tin-tuc', pathname),
                })}
                onClick={() => setIsShowMenu(false)}>
                <Link to="/tin-tuc">Tin tức</Link>
              </li>
              <li
                className={classNames({
                  activePage: isActive('/kols', pathname),
                })}
                onClick={() => setIsShowMenu(false)}>
                <Link to="/kols">KOLs</Link>
              </li>
              <li
                className={classNames({
                  activePage: isActive('/tuyen-dung', pathname),
                })}
                onClick={() => setIsShowMenu(false)}>
                <Link to="/tuyen-dung">Tuyển dụng </Link>
              </li>
              <li onClick={() => setIsShowMenu(false)}>
                <Link className={'launch'} to="/lien-he">
                  liên hệ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
